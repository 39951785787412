import React from "react";

import {Link} from "gatsby";
import styles from "./post_link.module.css";

export default (props) => (
  <li className={styles.li}>
    <span className={styles.date}>{props.date}</span>
    <h3 className={styles.h3}>
      <Link className={styles.link} to={props.link}>{props.children}</Link>
    </h3>
  </li>
);
